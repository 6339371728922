import { Box } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import LandingFooter from './components/LandingFooter';

const LandingAppeals: FC = () => {
  const url = useMemo(() => {
    return process.env.REACT_APP_FLUTTER_URL;
  }, []);

  return (
    <div>
      <Box height="calc(100vh - 80px)">
        <iframe
          width="100%"
          height="100%"
          style={{
            height: 'calc(100vh) - 80px)',
          }}
          title="Appeals"
          src={`${url}/#/free-appeal`}
        />
      </Box>
      <LandingFooter />
    </div>
  );
};

export default LandingAppeals;
