import axios from 'axios';
import { getAxiosInstance } from './axios-instance';

const testEnv = process.env.REACT_APP_ENV === 'PROD' ? '/version-live' : '/version-test';
const forumHost = process.env.REACT_APP_FORUM_API_HOST;
const optimizerHost = process.env.REACT_APP_ENV === 'PROD'
  ? 'https://southamerica-east1-medq-prod.cloudfunctions.net/optimizer'
  : 'https://southamerica-east1-dynamic-clinical-pathways-dev.cloudfunctions.net/optimizer';

const api = axios.create({
  baseURL: `https://aristoacademy.com.br${testEnv}/api/1.1/`,
});

export const api2 = getAxiosInstance(forumHost || '', { 'request-origin': 'react' });
// api2.defaults.headers.common.Authorization = `Bearer ${process.env.REACT_APP_FORUM_AUTH_TOKEN}`;

export default api;

export const optimizerApi = getAxiosInstance(
  optimizerHost,
  { 'user-source': 'Aristo' },
);
