import { Center, Flex, VStack, Box } from '@chakra-ui/react';
import moment from 'moment';
import React, { FC, useCallback, useState } from 'react';
import { Loading } from '../../../../lib/components/Loading';
import { Forum2Comment } from './Forum2Comment.component';
import { useForum2API } from '../../../../hooks/useForum2API';
import { CommentDtoProps, ForumCommentWithAnswersType } from '../types/forum2.types';

export const Forum2CommentWithAnswers: FC<ForumCommentWithAnswersType> = ({
  commentData, redirectUrl, commentOfQuestion, parent, onDelete, level, contentId, contentType, courseIdFromParam,
}: ForumCommentWithAnswersType) => {
  const [showAnswers, setShowAnswers] = useState(false);
  const [answerCount, setAnswerCount] = useState(commentData.children.length);
  const [comments, setComments] = useState<CommentDtoProps[]>([]);
  const { getReplies, deleteComment } = useForum2API();
  const [loadingComments, setLoadingComments] = useState(false);

  const refetchAnswers = useCallback(async () => {
    const res = await getReplies({ ids: commentData.children });

    setComments(res);

    setLoadingComments(false);
  }, [commentData.children, getReplies]);

  const handleDeleteComment = useCallback(async (id: string) => {
    await deleteComment({
      commentId: id,
    });

    setComments(prev => prev.filter(e => e.id !== id));
  }, [deleteComment]);

  const handleCreateComment = useCallback((v: CommentDtoProps) => {
    setComments(prev => [v, ...prev]);
    setShowAnswers(true);
    setAnswerCount(prev => prev + 1);
  }, []);

  return (
    <Flex flexFlow="column" w="100%">
      <Forum2Comment
        setShowAnswers={setShowAnswers}
        showAnswers={showAnswers}
        setLoadingMoreSecondLevelComments={setLoadingComments}
        refetchAnswers={refetchAnswers}
        parent={parent?.name}
        comment={commentData}
        redirectUrl={redirectUrl}
        commentOfQuestion={commentOfQuestion}
        onDelete={async () => onDelete(commentData.id)}
        level={level}
        contentId={contentId}
        contentType={contentType}
        onCreate={handleCreateComment}
        answersCount={answerCount}
        courseIdFromParam={courseIdFromParam}
      />
      <VStack
        display={!showAnswers ? 'none' : undefined}
        pl={{ base: 5, md: 10 }}
        align="flex-end"
      >
        <Flex flexFlow="row">
          <Box
            backgroundColor="lightGray"
            width="1px"
          />
          <Flex flexFlow="column" flex="1">
            {comments && comments
              .sort((a, b) => {
                return moment(a.createdAt) > moment(b.createdAt) ? 1 : -1;
              })
              .map(commentAnswer => {
                return (
                  <Forum2CommentWithAnswers
                    contentId={contentId}
                    key={commentAnswer.id}
                    commentData={commentAnswer}
                    redirectUrl={redirectUrl}
                    parent={commentData.user}
                    commentOfQuestion={commentOfQuestion}
                    onDelete={handleDeleteComment}
                    level={level + 1}
                    contentType={contentType}
                    courseIdFromParam={courseIdFromParam}
                  />
                );
              })}
          </Flex>
        </Flex>
        {loadingComments && (
          <Center w="100%" fontSize="sm">
            <Loading />
          </Center>
        )}
      </VStack>
    </Flex>
  );
};
