import { useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import React, { createContext, FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { CommentDtoProps, ContentType } from '../views/_components/Forum/types/forum2.types';
import { useForum2API } from '../hooks/useForum2API';
import { PrivateContext } from '../Private.context';

type DisclosureType = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  isControlled: boolean;
};

export interface Forum2Interface {
  comments: CommentDtoProps[],
  setComments: React.Dispatch<React.SetStateAction<CommentDtoProps[]>>,
  updateComments: (incomingComments: CommentDtoProps[]) => void;
  forumRef: React.RefObject<HTMLDivElement>;
  forumDisclosure: DisclosureType,
  isCurrUserSpecialist: boolean,
  setIsCurrUserSpecialist: React.Dispatch<React.SetStateAction<boolean>>,
  answerActive: string,
  setAnswerActive: React.Dispatch<React.SetStateAction<string>>,
  courseId: string,
  onCreateComment: (props: {
    body: string;
    parentId?: string;
    contentId: string;
    courseIdFromParam: string;
    level: number;
    contentType: ContentType;
  }) => Promise<CommentDtoProps>
}

export type UpdateCommentAnswersType = {
  firstLevelCommentId: string,
  newComments: CommentDtoProps[],
}

export const Forum2Context = createContext({} as Forum2Interface);

export const Forum2Storage: FC = ({ children }) => {
  const [comments, setComments] = useState<CommentDtoProps[]>([]);
  const [isCurrUserSpecialist, setIsCurrUserSpecialist] = useState(false);
  const [answerActive, setAnswerActive] = useState<string>('');

  const forumRef = useRef<HTMLDivElement>(null);
  const forumDisclosure = useDisclosure();
  const { createComment } = useForum2API();
  const { profile } = useContext(PrivateContext);

  const courseId = useMemo(() => {
    if (profile) {
      return profile.courses.map(c => c.course)[0];
    } return '';
  }, [profile]);

  const updateComments = useCallback((incomingComments: CommentDtoProps[]) => {
    setComments(prev => {
      const previousCommentsIds = prev.map(x => x.id);
      const nonExistentComments = incomingComments.filter(x => !previousCommentsIds.includes(x.id));
      const updatedAlreadyExistentComments = prev.map(prevComment => {
        const currComment = incomingComments.filter(x => x.id === prevComment.id)[0];
        const updatedComment = { ...prevComment, ...currComment };
        return updatedComment;
      });

      const sortedComments = [...updatedAlreadyExistentComments, ...nonExistentComments]
        .sort((a, b) => {
          return moment(a.createdAt) > moment(b.createdAt) ? -1 : 1;
        });
      return sortedComments;
    });
  }, [setComments]);

  const onCreateComment = useCallback(async ({
    body,
    parentId,
    contentId,
    level,
    contentType,
    courseIdFromParam,
  }: {
    body: string;
    parentId?: string;
    contentId: string;
    level: number,
    contentType: ContentType,
    courseIdFromParam?: string,
  }) => {
    const res = await createComment({
      body,
      parentId,
      contentId,
      contentType,
      courseId: courseIdFromParam ?? courseId,
    });

    const newComment: CommentDtoProps = {
      body,
      children: [],
      contentId,
      contentType,
      courseId,
      createdAt: (new Date()).toISOString(),
      id: res,
      level: level - 1,
      user: {
        email: profile?.email || '',
        name: profile?.name || '',
        id: profile?._id || '',
        externalId: profile?.uid,
      },
      parent: parentId,
      status: {
        isPublic: true,
        isFromModerator: false,
        answerStatus: 'waitingAnswer',
      },
    };

    return newComment;
  }, [courseId, createComment, profile?._id, profile?.email, profile?.name, profile?.uid]);

  return (
    <Forum2Context.Provider
      value={{
        isCurrUserSpecialist,
        setIsCurrUserSpecialist,
        forumDisclosure,
        comments,
        setComments,
        updateComments,
        forumRef,
        onCreateComment,
        answerActive,
        setAnswerActive,
        courseId,
      }}
    >
      {children}
    </Forum2Context.Provider>
  );
};
